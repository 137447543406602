import React from 'react'
import PropTypes from 'prop-types'

import {
    Box,
    Text,
    useStyleConfig,
    useDisclosure,
    List,
    ListItem
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {WeChatModal} from '../../../components/wechat-modal'
import {Component} from '@salesforce/commerce-sdk-react/components'
import WeChatButton from '../../../components/footer/wechat-button'

/**
 * PDCFooterLinksListDesktop component renders a list of footer links for desktop.
 *
 * @param {string} props.title - The title to display above the list of links.
 * @param {Array} props.regions - An array of region objects containing components to render.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const PDCFooterLinksListDesktop = ({regions, title}) => {
    const components = regions?.[0]?.components || []
    const {isOpen: isWeChatOpen, onOpen: onWeChatOpen, onClose: onWeChatClose} = useDisclosure()

    const styles = useStyleConfig('Footer')
    const linkMarginBottom = {
        '&:not(:last-of-type)': {
            marginBottom: ' 2px'
        }
    }
    return (
        <Box>
            <Text
                role={'heading'}
                aria-level={2}
                variant={'bodyBase2'}
                fontSize={10}
                fontWeight={500}
                mb={'20px'}
                textTransform={'uppercase'}
            >
                {title}
            </Text>
            <List role={undefined}>
                {components.map((link, index) => {
                    const {fontColor = styles.link.color, fontSize = '12px', label} = link.data
                    const isWeChat = label.toLowerCase() === 'wechat'

                    return (
                        <ListItem key={index} sx={linkMarginBottom}>
                            {isWeChat ? (
                                <React.Fragment>
                                    <WeChatButton
                                        fontColor={fontColor}
                                        fontSize={fontSize}
                                        label={label}
                                        onWeChatOpen={onWeChatOpen}
                                    />
                                    <WeChatModal isOpen={isWeChatOpen} onClose={onWeChatClose} />
                                </React.Fragment>
                            ) : (
                                <Component
                                    component={{
                                        ...link,
                                        fontColor,
                                        fontSize,
                                        textDecoration: 'none',
                                        display: 'inline-block'
                                    }}
                                />
                            )}
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}

export default PDCFooterLinksListDesktop

PDCFooterLinksListDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
