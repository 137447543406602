import React from 'react'
import {Link as RouteLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {
    Box,
    Text,
    Flex,
    HStack,
    Link,
    Divider,
    Heading
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useParams} from 'react-router-dom'
import {usePage} from '@salesforce/commerce-sdk-react'
import {ChevronLeftIcon} from '../../../components/custom-icons'
import PDCBreadcrumb from '../../../components/pdc-breadcrumb/index.jsx'
import {useUrlFromLink} from '../../helpers/urlHelper.js'
import {
    BLOG_FOLDER_ID,
    MAGAZINE_HOME_PAGETYPE,
    MAGAZINE_CATEGORY_PAGETYPE,
    MAGAZINE_ARTICLE_PAGETYPE
} from '../../../constants.js'

// Utils
import {getEnvStaleTime} from '../../../utils/utils.js'

/**
 * @name PDCBlogSubmenu
 * @description Renders the Blog breadcrumb, back link, title and submenu
 * @param {Object} custom - Custom component
 */
export const PDCBlogSubmenu = ({custom}) => {
    const intl = useIntl()
    const {pageId} = useParams()
    const staleTime = getEnvStaleTime()
    const {data: currentPage} = usePage({parameters: {pageId}}, {staleTime: staleTime})
    const {data: magazineHomePage} = usePage(
        {parameters: {pageId: BLOG_FOLDER_ID}},
        {staleTime: staleTime}
    )

    // Get magazine category home pages from custom prop
    const magazineCategoryHomePages = custom?.magazineCategoryHomePages || []

    const isHomePage = currentPage?.typeId === MAGAZINE_HOME_PAGETYPE
    const isCategoryPage = currentPage?.typeId === MAGAZINE_CATEGORY_PAGETYPE
    const isArticlePage = currentPage?.typeId === MAGAZINE_ARTICLE_PAGETYPE
    const blogUrl = useUrlFromLink({
        type: 'PAGE',
        target: BLOG_FOLDER_ID
    })

    return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
            {/* Breadcrumb */}
            <PDCBreadcrumb
                currentPage={currentPage}
                homePage={magazineHomePage}
                homeUrl={blogUrl}
                isHomePage={isHomePage}
                isCategoryPage={isCategoryPage}
            />
            {/* Back Link */}
            {!isHomePage && (
                <Box w="full" textAlign="left">
                    <Link
                        as={RouteLink}
                        to={blogUrl}
                        textDecoration={'none'}
                        display={'inline-flex'}
                        alignItems={'center'}
                        p="18px 20px"
                    >
                        <ChevronLeftIcon boxSize={3} />
                        <Text
                            as="span"
                            fontSize="14px"
                            fontWeight="600"
                            ml="8"
                            textTransform={'uppercase'}
                        >
                            {intl.formatMessage({
                                id: 'magazine.header.back_link',
                                defaultMessage: 'back to our story'
                            })}
                        </Text>
                    </Link>
                </Box>
            )}
            {/* Page Title and submenu */}
            {!isArticlePage && (
                <Box p={['44px 0 19px', null, '34px 0']} textAlign={'center'}>
                    <Heading as="h1" fontSize={['4xl', null, '5xl']} textTransform="uppercase">
                        {currentPage?.name}
                    </Heading>
                    <nav>
                        <HStack
                            as="ul"
                            mt={['37px', null, '20px']}
                            justifyContent={'center'}
                            listStyleType={'none'}
                            gap="0"
                        >
                            {magazineCategoryHomePages?.map((child, i) => (
                                <Flex as="li" key={i} alignItems="center" justifyContent="center">
                                    {currentPage.id === child.id ? (
                                        <Text variant="bodyBase1" textDecoration="underline">
                                            {child?.name}
                                        </Text>
                                    ) : (
                                        <Link
                                            as={RouteLink}
                                            textDecoration="none"
                                            to={`/page/${child?.id}`}
                                        >
                                            {child?.name}
                                        </Link>
                                    )}
                                    {i !== magazineCategoryHomePages.length - 1 && (
                                        <Divider
                                            orientation="vertical"
                                            color="gray"
                                            mx={['0.5rem', null, '1.5rem']}
                                            height="13px"
                                        />
                                    )}
                                </Flex>
                            ))}
                        </HStack>
                    </nav>
                </Box>
            )}
        </Flex>
    )
}

PDCBlogSubmenu.propTypes = {
    custom: PropTypes.object
}
