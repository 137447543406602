import {COUNTRIES_CURRENCY, DISPATCH_COUNTRIES} from '../constants'

/**
 * Retrieves the shipping configuration for a specified country.
 *
 * * The function takes a country code as input and performs the following steps:
 * 1. Checks if the input countryCode is falsy. If so, returns a default configuration for France (FR) using the Euro (EUR) currency.
 * 2. Filters a predefined list (COUNTRIES_CURRENCY) to find the configuration that matches the given countryCode.
 * 3. Returns the matching configuration or undefined if no match is found.
 *
 * @param {string} countryCode - The country code to look up within the site's configuration.
 * @returns {Object} - An object representing the country's shipping configuration, including its ID and currency.
 *                     Defaults to using France (FR) and Euro (EUR) if countryCode is not provided.
 */
export const getShipToCountry = (countryCode) => {
    // if no countries are configured, return default country
    const countries = COUNTRIES_CURRENCY
    if (!countryCode) {
        return {
            id: 'FR',
            currency: 'EUR'
        }
    }
    // get country config
    const country = countries.filter((country) => country.id === countryCode)[0]
    return country
}

/**
 * Sorts the shipping countries by name.
 * @returns {code: string, name: string}[] - an array of sorted countries by name
 */
export const sortShipToCountries = () => {
    return DISPATCH_COUNTRIES.flatMap((country) => country.countries).sort((a, b) =>
        a.name.localeCompare(b.name)
    )
}
