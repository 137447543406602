import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Controller} from 'react-hook-form'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Checkbox
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {RequiredIndicator} from '@chakra-ui/react'
import {VisibilityIcon, VisibilityOffIcon, ChevronDownIcon, CheckIcon} from '../custom-icons'
import {useIntl} from 'react-intl'
import {OPTIONAL_INDICATOR_TEXT, SELECT_OPTION_TEXT} from '../../constants'

/**
 * CustomCheckboxIcon component renders a custom icon for checkboxes.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isChecked - Indicates if the checkbox is checked.
 * @param {boolean} props.isIndeterminate - Indicates if the checkbox is in an indeterminate state.
 * @returns {JSX.Element} The rendered component.
 */

const CustomCheckboxIcon = ({isChecked, isIndeterminate, ...rest}) => {
    return <>{isChecked ? <CheckIcon boxSize={'10px'} {...rest} /> : null}</>
}

CustomCheckboxIcon.propTypes = {
    isChecked: PropTypes.bool,
    isIndeterminate: PropTypes.bool
}

/**
 * Field component is a versatile form field component that supports various input types.
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the field.
 * @param {string} props.label - The label of the field.
 * @param {string} [props.formLabel] - The form label of the field.
 * @param {string} [props.type='text'] - The type of the field.
 * @param {Array} [props.options=[]] - The options for select fields.
 * @param {Object} [props.rules={}] - The validation rules for the field.
 * @param {Object} [props.error] - The error object containing error messages.
 * @param {string} [props.placeholder] - The placeholder text for the field.
 * @param {Object|Function} [props.inputProps] - Additional props for the input element.
 * @param {Object} props.control - The control object from react-hook-form.
 * @param {string} [props.autoComplete] - The autocomplete attribute for the input.
 * @param {*} [props.defaultValue] - The default value of the field.
 * @param {*} [props.helpText] - The help text for the field.
 * @param {boolean} [props.isDisabled] - Indicates if the field is disabled.
 * @param {boolean} [props.hasIndicator=true] - Indicates if the field has an optional indicator.
 * @param {boolean} [props.isPlaceholder=false] - Indicates if the field is a placeholder.
 * @param {string} [props.placeholderText] - The placeholder text for select fields.
 * @param {*} [props.children] - The child elements to render inside the field.
 * @param {Object} [props.inputRef] - The ref object for the input element.
 * @returns {JSX.Element} The rendered component.
 */

const Field = ({
    name,
    label,
    formLabel,
    type = 'text',
    options = [],
    rules = {},
    error,
    placeholder,
    inputProps,
    control,
    autoComplete,
    defaultValue,
    helpText,
    isDisabled,
    hasIndicator = true,
    isPlaceholder = false,
    placeholderText,
    children,
    inputRef
}) => {
    const intl = useIntl()
    const [hidePassword, setHidePassword] = useState(true)
    const PasswordIcon = hidePassword ? VisibilityIcon : VisibilityOffIcon
    const passwordIconLabel = hidePassword
        ? intl.formatMessage({
              id: 'field.password.assistive_msg.show_password',
              defaultMessage: 'Show password'
          })
        : intl.formatMessage({
              id: 'field.password.assistive_msg.hide_password',
              defaultMessage: 'Hide password'
          })
    const inputType =
        type === 'password' && hidePassword ? 'password' : type === 'password' ? 'text' : type

    const selectPlaceholder =
        isPlaceholder && (placeholderText || intl.formatMessage(SELECT_OPTION_TEXT))

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({field: {onChange, value, ref}}) => {
                const _inputProps =
                    typeof inputProps === 'function' ? inputProps({value, onChange}) : inputProps

                return (
                    <FormControl
                        variant={!['checkbox', 'radio', 'hidden'].includes(type) ? 'floating' : ''}
                        id={name}
                        isInvalid={error}
                        isDisabled={isDisabled}
                        isRequired={!!rules.required}
                    >
                        <InputGroup>
                            {[
                                'text',
                                'password',
                                'email',
                                'phone',
                                'tel',
                                'number',
                                'date'
                            ].includes(type) && (
                                <>
                                    <Input
                                        variant="basic"
                                        ref={(node) => {
                                            ref(node)
                                            if (inputRef) inputRef.current = node
                                        }}
                                        onChange={onChange}
                                        value={value}
                                        type={inputType}
                                        placeholder={placeholder || ''}
                                        autoComplete={autoComplete}
                                        {...(type === 'date' && {max: '9999-12-31'})}
                                        {..._inputProps}
                                    />
                                </>
                            )}

                            {type === 'hidden' && (
                                <input
                                    ref={ref}
                                    onChange={onChange}
                                    value={value}
                                    type="hidden"
                                    {..._inputProps}
                                />
                            )}

                            {type === 'password' && (
                                <InputRightElement>
                                    <IconButton
                                        size="inputElement"
                                        aria-label={passwordIconLabel}
                                        icon={
                                            <PasswordIcon
                                                color="fullBlack"
                                                boxSize={5}
                                                svgTitle={passwordIconLabel}
                                                svgDescription={passwordIconLabel}
                                            />
                                        }
                                        onClick={() => setHidePassword(!hidePassword)}
                                    />
                                </InputRightElement>
                            )}

                            {type === 'select' && (
                                <Select
                                    icon={<ChevronDownIcon boxSize={5} />}
                                    variant="basic"
                                    ref={ref}
                                    onChange={onChange}
                                    value={value}
                                    {...(isPlaceholder && {
                                        placeholder: selectPlaceholder
                                    })}
                                    {..._inputProps}
                                >
                                    {options.map((opt) => (
                                        <option key={`${opt.label}-${opt.value}`} value={opt.value}>
                                            {opt.label}
                                        </option>
                                    ))}
                                </Select>
                            )}

                            {type === 'checkbox' && (
                                <Checkbox
                                    icon={<CustomCheckboxIcon />}
                                    iconSize="0.1rem"
                                    ref={ref}
                                    onChange={(e) => onChange(e.target.checked)}
                                    isChecked={value}
                                    isRequired={!!rules.required}
                                    {..._inputProps}
                                >
                                    {formLabel || label}
                                    {Object.prototype.hasOwnProperty.call(rules, 'required') && (
                                        <RequiredIndicator />
                                    )}
                                </Checkbox>
                            )}

                            {children}
                        </InputGroup>

                        {!['checkbox', 'radio', 'hidden'].includes(type) &&
                            (formLabel || (
                                <FormLabel
                                    variant={['select', 'date'].includes(type) && 'unchanged'}
                                    {...(hasIndicator && {
                                        optionalIndicator:
                                            ' ' + intl.formatMessage(OPTIONAL_INDICATOR_TEXT)
                                    })}
                                >
                                    {label}
                                </FormLabel>
                            ))}

                        {error && type !== 'hidden' && (
                            <FormErrorMessage>{error.message}</FormErrorMessage>
                        )}

                        {helpText}
                    </FormControl>
                )
            }}
        />
    )
}

Field.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    autoComplete: PropTypes.string,
    formLabel: PropTypes.any,
    type: PropTypes.oneOf([
        'text',
        'number',
        'password',
        'email',
        'phone',
        'tel',
        'select',
        'checkbox',
        'hidden',
        'date'
    ]),
    options: PropTypes.arrayOf(PropTypes.shape({label: PropTypes.string, value: PropTypes.any})),
    rules: PropTypes.object,
    error: PropTypes.shape({message: PropTypes.string}),
    placeholder: PropTypes.string,
    inputProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    control: PropTypes.object,
    defaultValue: PropTypes.any,
    helpText: PropTypes.any,
    isDisabled: PropTypes.bool,
    hasIndicator: PropTypes.bool,
    isPlaceholder: PropTypes.bool,
    placeholderText: PropTypes.string,
    children: PropTypes.any,
    inputRef: PropTypes.object
}

export default Field
