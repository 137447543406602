'use strict'

import {useShopperCustomersMutation} from '@salesforce/commerce-sdk-react'
import {useCurrentCustomer} from '@salesforce/retail-react-app/app/hooks/use-current-customer'

/**
 * Custom hook for updating customer profile fields related to consent.
 *
 * This hook provides functions to update the consent fields for a customer profile
 * in both EU and US contexts. It utilizes a mutation to update the customer data
 * and handles any errors that may occur during the update process.
 *
 * @returns {Object} An object containing methods to update consent fields:
 *
 * @property {Function} updateEUConsentFields - Updates the EU consent fields for a customer.
 *   @async
 *   @returns {Promise<boolean>} A promise that resolves to true if the update was successful, or false if an error occurred.
 *
 * @property {Function} updateUSConsentFields - Updates the US consent fields for a customer profile.
 *   @async
 *   @returns {Promise<boolean>} A promise that resolves to true if the update is successful, or false if an error occurs.
 */
export const useUpdateProfileFields = () => {
    const updateCustomerMutation = useShopperCustomersMutation('updateCustomer')
    const {data: customer} = useCurrentCustomer()
    const {customerId} = customer

    return {
        /**
         * Updates the EU consent fields for a customer.
         *
         * This function attempts to update the customer's marketing consent fields by 
         * sending a mutation request. If the update is successful, it returns true. 
         * If an error occurs during the update process, it catches the exception and 
         * returns false.
         *
         * @async
         * @returns {Promise<boolean>} A promise that resolves to true if the update 
         * was successful, or false if an error occurred.
         */
        async updateEUConsentFields() {
            try {
                await updateCustomerMutation.mutate({
                    parameters: {customerId},
                    body: {
                        c_marketingConsent: true,
                        c_marketingConsentDate: new Date()
                    }
                })
                return true
            } catch (e) {
                return false
            }
        },

        /**
         * Updates the US consent fields for a customer profile.
         * This function attempts to update the customer's consent fields by calling a mutation.
         * If the update is successful, it returns true; otherwise, it returns false.
         *
         * @async
         * @returns {Promise<boolean>} - A promise that resolves to true if the update is successful, or false if an error occurs.
         */
        async updateUSConsentFields() {
            try {
                await updateCustomerMutation.mutate({
                    parameters: {customerId},
                    body: {
                        c_shareConsent: true,
                        c_shareConsentDate: new Date()
                    }
                })
                return true
            } catch (e) {
                return false
            }
        }
    }
}
