'use strict'

// PWA OOTB Hooks
import React, {useMemo, useState} from 'react'
import {useAccessToken} from '@salesforce/commerce-sdk-react'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import useSiteType from './use-site-type'
import {useCurrentCustomer} from '@salesforce/retail-react-app/app/hooks/use-current-customer'
import {
    setLocalStorageJSONItem,
    getLocalStorageJSONItem,
    clearLocalStorageJSONItem,
    getContactData
} from './../utils/utils'
import useShipToCountry from './use-ship-to-country'

/**
 * Custom hook that provides functionality to interact with the Splio service.
 *
 * @returns {Object} An object containing the `sendBasketToSplio` function.
 *
 * @property {Function} sendBasketToSplio - Sends the current basket information to the Splio service.
 *
 * @function
 * @name sendBasketToSplio
 * @async
 * @returns {Promise<Object>} A promise that resolves to an object containing the success status and message.
 * @property {boolean} success - Indicates whether the operation was successful.
 * @property {string} message - A message providing additional information about the operation's result.
 *
 * @throws {Error} If there is an issue with the network request or any other error occurs during the process.
 */
export const useSplioService = () => {
    const {shipToCountry} = useShipToCountry()
    const {getTokenWhenReady} = useAccessToken()
    const {app: appConfig} = getConfig()
    const {site, locale} = useMultiSite()
    const {isEU, isOutlet} = useSiteType()
    const {data: customer} = useCurrentCustomer()
    let [currentCustomerEmail, setCurrentCustomerEmail] = useState(null)
    let [splioAccountData, setSplioAccountData] = useState(null)
    let accessToken = ''

    useMemo(async () => {
        try {
            accessToken = await getTokenWhenReady()
            let contactDataResult = await getContactData({
                contact_id: currentCustomerEmail,
                locale: locale.id,
                siteId: site.id,
                accessToken: accessToken,
                orgId: appConfig.commerceAPI.parameters.organizationId
            })

            if (contactDataResult.success) {
                setSplioAccountData(contactDataResult.data)
            } else {
                setSplioAccountData(null)
            }
        } catch (error) {
            return error
        }
    }, [currentCustomerEmail])

    return {
        /**
         * Checks if an email is blacklisted by making an asynchronous request to the Splio service.
         *
         * @async
         * @param {Object} params - The parameters for the request.
         * @param {string} params.email - The email address to check against the blacklist.
         * @param {string} params.locale - The locale to be used in the request.
         * @param {string} params.siteId - The site identifier for the request context.
         * @param {string} params.accessToken - The access token for authentication.
         * @returns {Promise<Object>} A promise that resolves to an object containing the result of the blacklist check.
         * @property {boolean} success - Indicates if the email is blacklisted.
         * @property {string} message - A message describing the result of the blacklist check.
         *
         * @throws Will throw an error if the fetch request fails.
         */
        async isBlackListedEmail(params) {
            let blackListResult = {
                success: false,
                message: ''
            }

            try {
                accessToken = await getTokenWhenReady()

                let body = {
                    contact_id: params.email,
                    locale: params.locale,
                    siteId: params.siteId,
                    accessToken: accessToken,
                    orgId: appConfig.commerceAPI.parameters.organizationId
                }

                const response = await fetch('/api/splio/is-blacklisted-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    ...(body && {
                        body: JSON.stringify(body)
                    })
                })

                const result = await response.json()

                if (result.success) {
                    blackListResult.success = true
                    blackListResult.message = 'This email is already subscribed.'
                } else {
                    blackListResult.success = false
                    blackListResult.message = ''
                }

                return blackListResult
            } catch (error) {
                blackListResult.success = false
                blackListResult.message = 'This email is already subscribed.'

                return blackListResult
            }
        },

        /**
         * Checks if an email is already subscribed to the newsletter using the Splio service.
         *
         * @async
         * @param {Object} params - The parameters for the subscription check.
         * @param {string} params.email - The email address to check for subscription status.
         * @param {string} params.locale - The locale associated with the request.
         * @param {string} params.siteId - The site identifier for the request context.
         * @returns {Promise<Object>} A promise that resolves to an object containing the subscription status.
         * @property {boolean} success - Indicates if the email is already subscribed.
         * @property {string} message - A message providing additional information about the subscription status.
         *
         * @throws Will return an object with `success` set to false and an empty `message` if an error occurs during the process.
         */
        async isAlreadySubscribed(params) {
            const newsletterResult = {
                success: false,
                message: ''
            }

            if (!params.email) {
                newsletterResult.success = false
                newsletterResult.message = 'email is not found in request'
            }

            try {
                accessToken = await getTokenWhenReady()
                let contactDataResult

                if (!splioAccountData) {
                    contactDataResult = await getContactData({
                        contact_id: params.email,
                        locale: params.locale,
                        siteId: params.siteId,
                        accessToken: accessToken,
                        orgId: appConfig.commerceAPI.parameters.organizationId
                    })

                    if (contactDataResult.success) {
                        setSplioAccountData(contactDataResult.data)
                    } else {
                        setSplioAccountData(null)
                    }
                } else {
                    contactDataResult = splioAccountData
                }

                if (contactDataResult) {
                    if (!contactDataResult.data.lists || contactDataResult.data.lists.length == 0) {
                        newsletterResult.success = false
                        newsletterResult.message = 'Account does not exist in Splio'
                        return newsletterResult
                    }

                    let splioListId = params.siteId.indexOf('OUTLET') > -1 ? 1 : 0
                    let isRegistered = contactDataResult.data.lists.some(function (list) {
                        return list.id == splioListId;
                    })

                    if (isRegistered) {
                        newsletterResult.success = true
                        newsletterResult.message = 'This email is already subscribed.'
                    } else {
                        newsletterResult.success = false
                        newsletterResult.message = 'Account does not exist in Splio'
                    }

                    return newsletterResult
                } else {
                    newsletterResult.success = false
                    newsletterResult.message = 'Account does not exist in Splio'
                }

                return newsletterResult
            } catch (error) {
                newsletterResult.success = false
                newsletterResult.message = ''
                return newsletterResult
            }
        },

        /**
         * Forwards account information to the Splio service.
         *
         * This asynchronous function sends customer account data to the Splio service
         * via a POST request. It constructs a request body with the provided customer
         * data and additional configuration details, then processes the response to
         * update the application state.
         *
         * @async
         * @param {Object} data - The customer data to be forwarded.
         * @param {string} data.email - The customer's email address.
         * @param {string} data.firstName - The customer's first name.
         * @param {string} data.lastName - The customer's last name.
         * @param {string} data.phoneHome - The customer's home phone number.
         * @returns {Promise<Object>} A promise that resolves to an object containing
         * the result of the operation, including success status, message, and any
         * returned data.
         *
         * @property {boolean} success - Indicates whether the operation was successful.
         * @property {string} message - A message describing the result of the operation.
         * @property {Object|null} data - The data returned from the Splio service, if any.
         *
         * @throws Will catch and handle any errors that occur during the fetch operation,
         * updating the result object with an error message.
         */
        async forwardAccountToSplio(data) {
            let accountResult = {
                success: false,
                message: '',
                data: null
            }

            const customerEmail = data.email

            if (!customerEmail) {
                return accountResult
            }

            accessToken = await getTokenWhenReady()

            try {
                setCurrentCustomerEmail(customerEmail)

                const body = {
                    contact_id: customerEmail,
                    content: {
                        firstname: data.firstName,
                        lastname: data.lastName,
                        cellphone: data.phoneHome
                    },
                    isOutlet: isOutlet,
                    shipToCountry: shipToCountry.id,
                    locale: locale.id,
                    siteId: site.id,
                    source: '',
                    title: {
                        value: ''
                    },
                    accessToken: accessToken,
                    orgId: appConfig.commerceAPI.parameters.organizationId,
                    splioAccountData: splioAccountData,
                    marketingConsent: data.marketingConsent ? data.marketingConsent : ''
                }

                const response = await fetch('/api/splio/forward-account', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    ...(body && {
                        body: JSON.stringify(body)
                    })
                })

                const result = await response.json()

                setSplioAccountData(result.data)

                if (result.success) {
                    accountResult.success = result.success
                    accountResult.message = result.message
                } else {
                    accountResult.success = false
                    accountResult.message = result.message
                }
            } catch (e) {
                accountResult.msg = e.message
                accountResult.success = false
            }
        },

        /**
         * Sends the current basket data to the Splio service.
         *
         * This function constructs a payload with the basket details and sends it to the Splio API endpoint.
         * It handles both guest and registered customers, and differentiates between EU and non-EU stores.
         * The function returns an object indicating the success status and a message from the API response.
         *
         * @async
         * @param {boolean} isCompleted - Indicates if the basket process is completed.
         * @param {Object} basket - The basket object containing details of the current shopping session.
         * @returns {Promise<Object>} An object containing the success status and message from the Splio API response.
         * @property {boolean} success - Indicates if the basket was successfully sent to Splio.
         * @property {string} message - A message returned from the Splio API, providing additional information.
         */
        async sendBasketToSplio(isCompleted, basket) {
            let basketResult = {
                success: false,
                message: ''
            }

            accessToken = await getTokenWhenReady()

            try {
                const customerEmail = basket?.customerInfo?.email || customer?.email

                if (!basket || !customerEmail) {
                    return basketResult
                }

                setCurrentCustomerEmail(customerEmail)

                let storeExternalId = ''

                if (isEU) {
                    storeExternalId = '802'
                } else {
                    storeExternalId = '807'
                }

                let body = {
                    external_id: basket?.basketId,
                    contact_id: customerEmail,
                    store_external_id: storeExternalId,
                    completed: isCompleted || false,
                    products: [],
                    shipToCountry: shipToCountry.id,
                    total_price: basket?.orderTotal || basket?.productTotal,
                    locale: locale.id,
                    siteId: site.id,
                    accessToken: accessToken,
                    orgId: appConfig.commerceAPI.parameters.organizationId,
                    splioAccountData: splioAccountData
                }

                for (let i = 0; i < basket?.productItems?.length; i++) {
                    const product = basket?.productItems[i]
                    body.products.push({
                        product_id: product.productId,
                        quantity: product.quantity,
                        currency: basket?.currency,
                        total_line_amount: product.price,
                        content: {
                            external_id: basket?.basketId
                        }
                    })
                }

                const response = await fetch('/api/splio/send-basket', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    ...(body && {
                        body: JSON.stringify(body)
                    })
                })

                const result = await response.json()

                if (result.success) {
                    setLocalStorageJSONItem('currentBasketId', {basketId: basket?.basketId})
                }

                basketResult.success = result.success
                basketResult.message = result.message
            } catch (error) {
                basketResult.msg = error.message
                basketResult.success = false
            }

            return basketResult
        },

        /**
         * Asynchronously removes the current basket from Splio by sending a delete request to the Splio API.
         *
         * @async
         * @returns {Promise<Object>} A promise that resolves to an object containing the result of the operation.
         * @returns {boolean} return.success - Indicates whether the operation was successful.
         * @returns {string} return.message - A message providing additional information about the operation's result.
         *
         * @throws {Error} If an error occurs during the fetch operation, the error message is captured in the result object.
         */
        async removeBasketFromSplio() {
            let basketResult = {
                success: false,
                message: ''
            }

            try {
                const basketIdObj = getLocalStorageJSONItem('currentBasketId')

                if (!basketIdObj) {
                    return basketResult
                }

                const accessToken = await getTokenWhenReady()
                const body = {
                    id: basketIdObj?.basketId,
                    siteId: site.id,
                    locale: locale.id,
                    accessToken: accessToken,
                    orgId: appConfig.commerceAPI.parameters.organizationId
                }

                const response = await fetch('/api/splio/delete-order', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    ...(body && {
                        body: JSON.stringify(body)
                    })
                })

                const result = await response.json()

                if (result.success) {
                    clearLocalStorageJSONItem('currentBasketId')
                }

                basketResult.success = result.success
                basketResult.message = result.message
            } catch (error) {
                basketResult.msg = error.message
                basketResult.success = false
            }

            return basketResult
        }
    }
}
