import React, {useState} from 'react'
import PropTypes from 'prop-types'

/**
 * This is the global state for the multiples sites and locales supported in the App.
 *
 * To use the context simply import them into the component requiring context
 * like the below example:
 *
 * import React, {useContext} from 'react'
 * import {MultiSiteContext} from './contexts'
 *
 * export const RootCurrencyLabel = () => {
 *    const {site,locale,urlTemplate} = useContext(MultiSiteContext)
 *    return <div>{site} {locale}</div>
 * }
 *
 * Alternatively you can use the hook provided by us:
 *
 * import {useMultiSite} from './hooks'
 *
 * const {site, locale, buildUrl} = useMultiSite()
 * @type {React.Context<unknown>}
 */
export const ShipToCountryContext = React.createContext()
export const ShipToCountryProvider = ({shipToCountry: initialShipToCountry = {}, children}) => {
    const [shipToCountry, setShipToCountry] = useState(initialShipToCountry)

    return (
        <ShipToCountryContext.Provider value={{shipToCountry, setShipToCountry}}>
            {children}
        </ShipToCountryContext.Provider>
    )
}

ShipToCountryProvider.propTypes = {
    children: PropTypes.node.isRequired,
    shipToCountry: PropTypes.shape({
        id: PropTypes.string,
        currency: PropTypes.string
    })
}

/**
 * Provides a context and provider for managing password-related state within a React application.
 *
 * The `PasswordContext` is used to share the password state across the component tree without
 * having to pass props down manually at every level.
 *
 * The `PasswordProvider` component initializes the password-related state and makes it available
 * to any components that are descendants of this provider.
 *
 * @constant {React.Context} PasswordContext - The context object for password management.
 *
 * @component
 * @param {Object} props - The properties passed to the provider component.
 * @param {React.ReactNode} props.children - The child components that will have access to the context.
 *
 * @returns {React.Element} A React element that provides the password context to its children.
 */
export const PasswordContext = React.createContext()
export const PasswordProvider = ({children}) => {
    const [codeVerifier, setCodeVerifier] = useState(null)

    return (
        <PasswordContext.Provider value={{codeVerifier, setCodeVerifier}}>
            {children}
        </PasswordContext.Provider>
    )
}

PasswordProvider.propTypes = {
    children: PropTypes.node.isRequired
}

/**
 * A hook for managing password state and actions
 * @returns {Object} Checkout data and actions
 */
export const usePasswordContext = () => {
    return React.useContext(PasswordContext)
}
