import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

/**
 * Custom hook to determine if the current locale matches the specified locale.
 *
 * @param {string} localeToCheck - The locale identifier to check against the current locale.
 * @returns {boolean} - Returns `true` if the current locale matches the specified locale, otherwise `false`.
 */

export const useIsLocale = (localeToCheck) => {
    const {locale} = useMultiSite()

    return locale.id === localeToCheck
}
