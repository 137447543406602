import React from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {Stack} from '@salesforce/retail-react-app/app/components/shared/ui'
import Suggestion from './suggestion'

/**
 * SearchSuggestions component that displays search suggestions for products, categories, and pages.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.suggestions - The suggestions object containing arrays of products, categories, and pages.
 * @param {Array<Object>} props.suggestions.products - An array of product suggestions.
 * @param {Array<Object>} props.suggestions.categories - An array of category suggestions.
 * @param {Array<Object>} props.suggestions.pages - An array of page suggestions.
 *
 * @returns {JSX.Element} The rendered component.
 */
const SearchSuggestions = ({suggestions, redirect}) => {
    const {products, categories, pages} = suggestions
    const intl = useIntl()

    const suggestionTypes = [
        {
            title: intl.formatMessage({
                id: 'search.suggestion.products.title',
                defaultMessage: 'Suggestions'
            }),
            suggestion: products
        },
        {
            title: intl.formatMessage({
                id: 'search.suggestion.categories.title',
                defaultMessage: 'Categories'
            }),
            suggestion: categories
        },
        {
            title: intl.formatMessage({
                id: 'search.suggestion.pages.title',
                defaultMessage: 'Pages'
            }),
            suggestion: pages
        }
    ]

    return (
        <Stack
            spacing={0}
            left={0}
            bottom={0}
            width={'100%'}
            direction={{base: 'column', lg: 'row'}}
            flexWrap={'wrap'}
            justify={{base: 'unset', lg: 'flex-end'}}
        >
            {suggestionTypes.map(
                ({title, suggestion}, index) =>
                    suggestion?.length > 0 && (
                        <Suggestion
                            key={index}
                            title={title}
                            suggestion={suggestion}
                            redirect={redirect}
                        />
                    )
            )}
        </Stack>
    )
}

SearchSuggestions.propTypes = {
    recentSearches: PropTypes.array,
    searchSuggestions: PropTypes.object,
    closeAndNavigate: PropTypes.func,
    suggestions: PropTypes.object,
    redirect: PropTypes.func
}

export default SearchSuggestions
