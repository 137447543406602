import React, {useState, useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import {
    Modal,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Select,
    Text,
    FormControl,
    FormLabel,
    useDisclosure
} from '@chakra-ui/react'
import {Button} from '@salesforce/retail-react-app/app/components/shared/ui/Button'
import {useToast} from '../../hooks/use-toast'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import useShipToCountry from '../../hooks/use-ship-to-country'
import {TURKEY_PARTNER_SITE, RUSSIA_PARTNER_SITE, HOME_HREF} from '../../constants'
import {getShipToCountry, sortShipToCountries} from '../../utils/ship-to-country-utils'
import {
    useShopperContext,
    useShopperContextsMutation,
    useUsid,
    useCommerceApi,
    useAccessToken
} from '@salesforce/commerce-sdk-react'
import {ChevronDownIcon, LoaderIcon} from '../custom-icons'
import {getLocalStorageJSONItem, clearLocalStorageJSONItem} from '../../utils/utils'

const CountrySelectorModal = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [geolocCountry, setGeolocCountry] = useState()
    const [geolocCountryCode, setGeolocCountryCode] = useState()
    const [selectedCountryCode, setSelectedCountryCode] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const {site, buildUrl} = useMultiSite()
    const {setShipToCountry} = useShipToCountry()
    const createShopperContext = useShopperContextsMutation('createShopperContext')
    const updateShopperContext = useShopperContextsMutation('updateShopperContext')
    const {usid} = useUsid()
    const {
        data: shopperContext,
        isSuccess,
        isLoading: isShopperContextLoading
    } = useShopperContext({
        parameters: {usid: usid}
    })
    const api = useCommerceApi()
    const {getTokenWhenReady} = useAccessToken()

    /**
     * Gets IP address of the user and country according to the IP address.
     * Then opens the country selector modal.
     */
    const getIPAndOpenModal = async () => {
        try {
            // Get IP address of the user
            const response = await fetch('/ip', {
                method: 'GET'
            })
            const result = await response.json()
            const userIP = result.clientIp // example IP: '212.58.75.254'

            if (!userIP) {
                throw new Error('Unable to retrieve IP address.')
            }

            // Create a shopper context to retrieve country according to user's IP
            const token = await getTokenWhenReady()
            const contextParams = {
                parameters: {
                    usid: usid
                },
                headers: {
                    authorization: `Bearer ${token}`
                },
                body: {
                    clientIp: userIP
                }
            }
            const resp = await api.shopperContexts.createShopperContext(contextParams, true)
            const geoLocation = resp?.headers?.get('x-geolocation')

            // Set variables for modal display and open it
            if (geoLocation) {
                const geoData = geoLocation.split('; ').reduce((acc, item) => {
                    const [key, value] = item.split(': ')
                    acc[key] = value
                    return acc
                }, {})
                setGeolocCountry(geoData['Country'])
                setGeolocCountryCode(geoData['CountryCode'])
                onOpen()
            }
        } catch (error) {
            console.error('Error fetching IP or updating shopper context:', error)
            toast({
                title: 'Error fetching user location.',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true
            })
        }
    }

    /**
     * Handles the country change.
     */
    const handleCountryChange = (event) => {
        setSelectedCountryCode(event.target.value)
    }

    /**
     * Handler for confirm button. Updates the shopper context with the country selected and closes the modal.
     */
    const handleConfirm = async () => {
        setIsLoading(true)
        // Handle redirection cases
        const preferredLocale = navigator.language.split('-')[0]
        if (selectedCountryCode === 'US' && site.alias === 'eu') {
            window.location.href = buildUrl(HOME_HREF, 'us', preferredLocale)
        } else if (selectedCountryCode !== 'US' && site.alias === 'us') {
            window.location.href = buildUrl(HOME_HREF, 'eu', preferredLocale)
        } else if (selectedCountryCode === 'TR') {
            window.location.href = TURKEY_PARTNER_SITE
        } else if (selectedCountryCode === 'RU') {
            window.location.href = RUSSIA_PARTNER_SITE
        } else {
            // Handle other cases
            try {
                const shipToCountry = getShipToCountry(selectedCountryCode)

                await setShopperContextCountry(shipToCountry)
                setShipToCountry(shipToCountry)
                toast({
                    title: 'Country updated successfully.',
                    description: '',
                    status: 'success',
                    duration: 3000,
                    isClosable: true
                })
                onClose()
            } catch (error) {
                toast({
                    title: 'An error occurred.',
                    description: error.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                })
            }
        }
        setIsLoading(false)
    }

    /**
     * Handler for close button. Updates the shopper context with the geolocation country and closes the modal.
     */
    const handleClose = async () => {
        const shipToCountry = getShipToCountry(geolocCountryCode)
        await setShopperContextCountry(shipToCountry)
        setShipToCountry(shipToCountry)
        onClose()
    }

    /**
     * Sets the shopper context country and currency.
     * Updates an existing shopper context if it already exists, otherwise creates a new shopper context.
     * @param {object} countryAndCurrency - The object containing the country and currency to set
     */
    const setShopperContextCountry = async ({id, currency}) => {
        const options = {
            parameters: {
                usid: usid
            },
            body: {
                geoLocation: {
                    countryCode: id
                },
                customQualifiers: {
                    currency: currency
                }
            }
        }

        if (shopperContext?.data) {
            await updateShopperContext.mutateAsync(options)
        } else {
            await createShopperContext.mutateAsync(options)
        }
    }

    // Concatenate and sort countries
    const countries = sortShipToCountries()

    useEffect(() => {
        const newCountryCode = getLocalStorageJSONItem('countryCode')

        /**
         * Determines whether geolocation should be checked and performs necessary actions based on the country code.
         *
         * This asynchronous function checks if a new country code is provided and updates the shopper context
         * and local storage accordingly. If no new country code is provided, it evaluates the success status
         * and loading state of the shopper context to decide whether to retrieve the IP address and open a modal.
         *
         * @async
         * @function shouldCheckGeolocation
         * @returns {Promise<void>} A promise that resolves when the necessary actions are completed.
         *
         * @description
         * - If a `newCountryCode` is available, the function retrieves the corresponding shipping country,
         *   updates the shopper context with this country, sets the shipping country, and clears the local
         *   storage item for 'countryCode'.
         * - If no `newCountryCode` is provided, the function checks the success and loading status of the
         *   shopper context. If the context is not successful and not loading, or if it is successful but
         *   lacks a geolocation country code, it triggers the retrieval of the IP address and opens a modal.
         */
        async function shouldCheckGeolocation() {
            if (newCountryCode) {
                const shipToCountry = getShipToCountry(newCountryCode)
                await setShopperContextCountry(shipToCountry)
                setShipToCountry(shipToCountry)
                clearLocalStorageJSONItem('countryCode')
            } else if (
                (!isSuccess && !isShopperContextLoading) ||
                (isSuccess && !shopperContext?.geoLocation?.countryCode)
            ) {
                getIPAndOpenModal()
            }
        }

        shouldCheckGeolocation()
    }, [isShopperContextLoading])

    return (
        <Modal isOpen={isOpen} onClose={handleClose} size="small" isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                    <FormattedMessage
                        id="country_selector_modal.title"
                        defaultMessage="CHANGE YOUR COUNTRY"
                    />
                </ModalHeader>
                <ModalBody>
                    <Text variant="bodyLarge2" mb="1rem">
                        <FormattedMessage
                            id="country_selector_modal.description"
                            defaultMessage="You are currently visiting Vilebrequin.com from {country}. Would you like to update your location?"
                            values={{country: geolocCountry}}
                        />
                    </Text>
                    <FormControl variant="floating">
                        <Select
                            onChange={handleCountryChange}
                            id="selectCountry"
                            variant="basic"
                            defaultValue={geolocCountryCode}
                            icon={<ChevronDownIcon />}
                        >
                            {countries.map((country) => (
                                <option key={country.code} value={country.code}>
                                    {country.name}
                                </option>
                            ))}
                        </Select>
                        <FormLabel htmlFor="selectCountry"></FormLabel>
                    </FormControl>
                </ModalBody>
                <ModalFooter width="full" p="0">
                    <Button
                        variant="primary"
                        onClick={handleConfirm}
                        isLoading={isLoading}
                        spinner={<LoaderIcon size={8} color="white" />}
                        w="full"
                    >
                        <FormattedMessage
                            id="country_selector_modal.button"
                            defaultMessage="Confirm"
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default CountrySelectorModal
