import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalContent,
    ModalCloseButton,
    ModalOverlay,
    Text
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {usePage} from '@salesforce/commerce-sdk-react'
import {PageContext, Component} from '@salesforce/commerce-sdk-react/components'
import {HEADER_PDC_PAGE_ID} from '../../../components/header/constants'
import {CloseIcon} from '../../../components/custom-icons'
import {PDCCheckoutHeaderHelpLinks} from '../../../page-designer/layouts'
import {PDCLink} from '../../../page-designer/assets'

// Utils
import {getEnvStaleTime} from '../../../utils/utils.js'

const PAGEDESIGNER_TO_COMPONENT = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcCheckoutHeaderHelpLinks': PDCCheckoutHeaderHelpLinks
}

const HelpModal = ({isOpen, onClose}) => {
    const intl = useIntl()

    // Get the header page from page designer to display the help links
    const staleTime = getEnvStaleTime()
    const {data: headerPage} = usePage(
        {parameters: {pageId: HEADER_PDC_PAGE_ID}},
        {staleTime: staleTime}
    )
    const checkoutHeaderHelpLinksComponent = headerPage?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.pdcCheckoutHeaderHelpLinks'
    )
    const [contextValue, setContextValue] = useState({components: PAGEDESIGNER_TO_COMPONENT})

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="small" isCentered>
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    <Text variant="">
                        {intl.formatMessage({
                            id: 'header.checkout.help.modal.title',
                            defaultMessage: 'HOW CAN WE HELP YOU?'
                        })}
                    </Text>
                </ModalHeader>

                <ModalCloseButton>
                    <CloseIcon boxSize="14px" />
                </ModalCloseButton>

                <ModalBody>
                    {/** Help links from page designer */}
                    <PageContext.Provider value={contextValue}>
                        <Component component={checkoutHeaderHelpLinksComponent} />
                    </PageContext.Provider>
                </ModalBody>

                <ModalFooter justifyContent="center" display="flex" width="full" p="0">
                    <Button variant={'link'} align="center" onClick={onClose}>
                        {intl.formatMessage({
                            id: 'header.checkout.help.modal.return',
                            defaultMessage: 'Return to Checkout'
                        })}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

HelpModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
}

export default HelpModal
