import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

const PageScrollLock = ({enabled, children}) => {
    useEffect(() => {
        document.body.style.overflow = enabled ? 'hidden' : ''

        return () => {
            document.body.style.overflow = ''
        }
    }, [enabled])

    return <>{children}</>
}

PageScrollLock.propTypes = {
    enabled: PropTypes.bool.isRequired,
    children: PropTypes.node
}

export default PageScrollLock
