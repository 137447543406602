import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {Box, Container, Stack, Text, IconButton, useBreakpointValue} from '@chakra-ui/react'
import {isHydrated} from '@salesforce/retail-react-app/app/utils/utils'
import {CloseIcon} from '../../../components/custom-icons'

/**
 * @typedef {Object} PDCHeaderTopBannerProps
 * @property {string} smallMessage - Small message.
 * @property {string} mediumMessage - Medium message.
 * @property {string} largeMessage - Large message.
 * @returns {React.ReactElement} - PDCHeaderTopBanner component.
 */
export const PDCHeaderTopBanner = ({smallMessage, mediumMessage, largeMessage, ...props}) => {
    const intl = useIntl()
    const message = useBreakpointValue({
        base: smallMessage,
        md: mediumMessage,
        lg: largeMessage
    })
    const [currentMessage, setCurrentMessage] = useState('')

    const [showBanner, setShowBanner] = useState(true)

    useEffect(() => {
        const data = window.localStorage.getItem('TOP_BANNER_STATE')
        if (data !== null) {
            setShowBanner(JSON.parse(data))
        } else {
            setShowBanner(true)
        }
    }, [])

    // Avoid a flash of wrong content displaying smallMessage waiting for hydratation
    useEffect(() => {
        if (isHydrated()) {
            setCurrentMessage(message)
        }
    }, [message])

    useEffect(() => {
        window.localStorage.setItem('TOP_BANNER_STATE', JSON.stringify(showBanner))
    }, [showBanner])

    return (
        showBanner &&
        currentMessage && (
            <Box as="section" maxWidth="container.xxxl" mx="auto" id="headerBanner">
                <Box color="white" bg="blue" position={'relative'}>
                    <Container
                        py={['10px', null, '6px']}
                        pl={['10px', null, '16px', null, '40px']}
                        pr={['32px', null, '34px', null, '58px']}
                    >
                        <Stack direction="row" justify="center" align={'center'}>
                            <Text variant="descriptor">{currentMessage}</Text>
                            <IconButton
                                position={'absolute'}
                                right={['0', null, '10px', null, '34px']}
                                padding={['10px', null, '6px']}
                                size="auto"
                                icon={<CloseIcon boxSize={3} />}
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Close banner',
                                    id: 'header.top_banner.close'
                                })}
                                onClick={() => setShowBanner(false)}
                            />
                        </Stack>
                    </Container>
                </Box>
            </Box>
        )
    )
}

PDCHeaderTopBanner.propTypes = {
    smallMessage: PropTypes.string,
    mediumMessage: PropTypes.string,
    largeMessage: PropTypes.string
}
