import React from 'react'
import PropTypes from 'prop-types'
import {
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    Button,
    Checkbox,
    FormControl
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useIntl} from 'react-intl'
import {useForm} from 'react-hook-form'

/**
 * NewsletterModal component for displaying a modal with a newsletter subscription form.
 *
 * This component renders a modal dialog that contains a form for subscribing to a newsletter.
 * It includes a consent checkbox and a submit button. The modal can be opened or closed
 * based on the `isOpen` prop, and a callback function is called upon form submission.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isOpen - Determines whether the modal is open or closed.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {function} props.submitNewsletter - Callback function to handle newsletter subscription submission.
 * @returns {JSX.Element} The rendered newsletter subscription modal.
 */

export const NewsletterModal = ({isOpen, onClose, submitNewsletter}) => {
    const intl = useIntl()
    const {
        formState: {errors}
    } = useForm()

    const onSubmit = (e) => {
        e.preventDefault()
        submitNewsletter()
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textTransform={'uppercase'}>
                    {intl.formatMessage({
                        id: 'newsletter.modal.title',
                        defaultMessage: 'Thank you'
                    })}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody mt={34}>
                    <form id={'newsletter-modal-form'} onSubmit={onSubmit}>
                        <FormControl
                            isInvalid={errors.newsletterConsent}
                            isRequired
                            maxW={'711'}
                            mb={'16px'}
                        >
                            <Checkbox
                                id="newsletterConsent"
                                name="newsletterConsent"
                                variant={'bodyBase2'}
                                required
                            >
                                {intl.formatMessage({
                                    id: 'newsletter.form.checkbox.consent',
                                    defaultMessage: `I accept to receive the latest news and promotional offers through the communication means which I have provided, in accordance with Vilebrequin's privacy policy. I can unsubscribe at any time via my online account or by clicking the unsubscribe link that appears in all Vilebrequin communications.*`
                                })}
                            </Checkbox>
                        </FormControl>
                        <Button type={'submit'} variant={'primary'} form={'newsletter-modal-form'}>
                            {intl.formatMessage({
                                id: 'newsletter.modal.button',
                                defaultMessage: 'Confirm'
                            })}
                        </Button>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

NewsletterModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    submitNewsletter: PropTypes.func
}
