import React from 'react'
import {Link as RouteLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {Link} from '@chakra-ui/react'
import {useUrlFromLink} from '../../helpers/urlHelper'

/**
 * @typedef {Object} PDCLinkProps
 * @property {string} url - URL to link to
 * @property {string} label - Label to display
 * @property {boolean} isExternal - Whether the link is external
 * @property {string} fontColor - Font color
 * @property {string} fontSize - Font size
 * @property {string} displayConfig - Display configuration (style of the link)
 * @property {string} backgroundColor - Background color
 * @property {string} textColor - Text color
 * @property {string} txtHorizontalPos - Text horizontal position
 * @property {string} defaultColor - Default color
 * @returns {React.ReactElement} - PDCLink component.
 */
export const PDCLink = ({
    url,
    label,
    isExternal,
    fontColor,
    fontSize,
    displayConfig,
    backgroundColor,
    textColor,
    txtHorizontalPos,
    defaultColor,
    width,
    custom,
    children,
    ...props
}) => {
    const linkDisplayConfigVariant = {
        standard: '',
        'transparent button': 'secondary',
        'inverted button': 'primary'
    }
    const variant = displayConfig ? linkDisplayConfigVariant[displayConfig] : ''

    const getColor = () => {
        if (variant === 'primary') {
            return fontColor || textColor ? backgroundColor : defaultColor || 'white'
        }
        return fontColor || textColor || defaultColor || 'fullBlack'
    }

    const getBorderColor = () => {
        if (variant === 'secondary') {
            return fontColor || textColor || defaultColor
        }
        return defaultColor || 'fullBlack'
    }

    const getBackgroundColor = () => {
        if (variant === 'primary') {
            return fontColor || textColor || defaultColor || 'fullBlack'
        }
        return 'transparent'
    }

    // We remove 'typeId' prop coming from the component itself to avoid react warning
    const propsToMerge = Object.fromEntries(
        Object.entries(props).filter((prop) => prop[0] !== 'typeId')
    )

    const updatedUrl = useUrlFromLink(custom?.url) || url
    const isLinkExternal = custom?.url?.type === 'EXTERNAL'

    // As this component is used in multiple contexts, we need to control the props from the parent
    // so we merge them with the default props and pass them to the link component
    const linkProps = {
        href: updatedUrl,
        to: updatedUrl,
        isExternal,
        color: getColor(),
        borderColor: getBorderColor(),
        fontSize: fontSize || '12px',
        bg: getBackgroundColor(),
        variant: variant,
        textAlign: variant === '' ? txtHorizontalPos : '',
        ...propsToMerge
    }

    return (
        updatedUrl && (
            <Link
                as={!(isExternal || isLinkExternal) && RouteLink}
                size={variant !== '' ? 'md' : 'auto'}
                width={width || 'auto'}
                display={variant === '' ? 'inline-block' : 'inline-flex'}
                {...linkProps}
            >
                {label || children || updatedUrl}
            </Link>
        )
    )
}

PDCLink.propTypes = {
    url: PropTypes.string,
    label: PropTypes.string,
    isExternal: PropTypes.bool,
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    displayConfig: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    defaultColor: PropTypes.string,
    txtHorizontalPos: PropTypes.string,
    width: PropTypes.string,
    typeId: PropTypes.string,
    custom: PropTypes.shape({
        url: PropTypes.shape({
            target: PropTypes.string,
            type: PropTypes.string
        })
    }),
    children: PropTypes.node
}
