import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {PDCAboutStoryBlockDefine} from '../pdc-about-story-block-define'

/**
 * PDC About Story Block Excerpt
 * @param {object} page - Page data
 * @param {string} backgroundColor - Background color
 * @param {string} textColor - Text color
 * @param {string} alternativePos - Secondary images and text position
 * @returns {JSX.Element} - PDC About Story Block Excerpt component
 */
export const PDCAboutStoryBlockExcerpt = ({page, backgroundColor, textColor, alternativePos}) => {
    const intl = useIntl()
    const aboutStoryBlockDefine = page.aboutStoryBlockDefine

    return (
        <PDCAboutStoryBlockDefine
            backgroundColor={backgroundColor || aboutStoryBlockDefine?.backgroundColor}
            textColor={textColor || aboutStoryBlockDefine?.textColor}
            alternativePos={alternativePos || aboutStoryBlockDefine?.alternativePos}
            regions={aboutStoryBlockDefine?.regions}
            title={aboutStoryBlockDefine?.title?.toUpperCase()}
            titleHeadingLevel={'h2'}
            titleFontColor={textColor || 'fullBlack'}
            subTitle={`${new Date(page?.publicationDate).toLocaleDateString(intl.locale, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })} - ${page.folderName}`.toUpperCase()}
            desc={aboutStoryBlockDefine?.desc}
            smallImage={aboutStoryBlockDefine?.smallImage}
            mediumImage={aboutStoryBlockDefine?.mediumImage}
            largeImage={aboutStoryBlockDefine?.largeImage}
            xlargeImage={aboutStoryBlockDefine?.xlargeImage}
            xxlargeImage={aboutStoryBlockDefine?.xxlargeImage}
            imageTitle={aboutStoryBlockDefine?.imageTitle}
            imageAlt={aboutStoryBlockDefine?.imageAlt}
            secLargeImage={aboutStoryBlockDefine?.secLargeImage}
            secXlargeImage={aboutStoryBlockDefine?.secXlargeImage}
            secXxlargeImage={aboutStoryBlockDefine?.secXxlargeImage}
            secImageTitle={aboutStoryBlockDefine?.secImageTitle}
            secImageAlt={aboutStoryBlockDefine?.secImageAlt}
            sec2LargeImage={aboutStoryBlockDefine?.sec2LargeImage}
            sec2XlargeImage={aboutStoryBlockDefine?.sec2XlargeImage}
            sec2XxlargeImage={aboutStoryBlockDefine?.sec2XxlargeImage}
            sec2ImageTitle={aboutStoryBlockDefine?.sec2ImageTitle}
            sec2ImageAlt={aboutStoryBlockDefine?.sec2ImageAlt}
            linkUrl={page?.id}
        />
    )
}

PDCAboutStoryBlockExcerpt.propTypes = {
    page: PropTypes.object,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    alternativePos: PropTypes.bool
}
