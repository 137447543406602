import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'

// Project Components
import {PDCLink} from '../../page-designer/assets/pdc-link'
import DrawerMenuLvl2 from './drawer-menu-lvl2'
import SEOMenu from './seo-menu'
import DrawerMenuLocaleSelector from './drawer-menu-locale-selector'

// Components
import {
    Box,
    Center,
    Divider,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Fade,
    HStack,
    Text,

    // Hooks
    useMultiStyleConfig,
    List,
    ListItem,
    useBreakpointValue
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useAnimation} from 'framer-motion'
import {useGesture} from 'react-use-gesture'

import {usePage} from '@salesforce/commerce-sdk-react'
import Link from '@salesforce/retail-react-app/app/components/link'

// Icons
import {CloseIcon, LoaderIcon} from '../custom-icons'

// Others
import {noop, getEnvStaleTime} from '../../utils/utils'
import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'

import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

import {NAV_MENU_ITEMS_FILTER, NAV_MENU_CONFIG_PDC_PAGE_ID} from './constants'

import useShipToCountry from '../../hooks/use-ship-to-country'

const PlaceholderComponent = () => (
    <Center p="8">
        <LoaderIcon size="lg" />
    </Center>
)

/** Drawer menu navigation component
 *
 * This is the navigation component for the drawer menu. It's
 * main usage is to navigate from one category to the next, but also homes links to
 * support, log in and out actions, as support links.
 *
 * @param {object} props
 * @param {object} props.root - The root category
 * @param {string} props.itemsKey - The key to the items array
 * @param {string} props.itemsCountKey - The key to the items count
 * @param {boolean} props.isOpen - Whether the drawer menu is open
 * @param {object} props.drawerMenuTriggerRef - The ref of the drawer menu trigger
 * @param {function} props.onClose - The function to close the drawer menu
 *
 * @returns {React.ReactElement}
 */
const DrawerMenu = ({
    root,
    itemsKey,
    itemsCountKey,
    isOpen,
    drawerMenuTriggerRef,
    onClose = noop,
    props
}) => {
    const intl = useIntl()
    const styles = useMultiStyleConfig('DrawerMenu')
    const {site, buildUrl} = useMultiSite()
    const {l10n} = site
    const [ariaBusy, setAriaBusy] = useState('true')

    const supportedLocaleIds = l10n?.supportedLocales.map((locale) => locale.id)
    const showLocaleSelector = supportedLocaleIds?.length > 1

    const [itemSelected, setItemSelected] = useState(null)
    const isMobile = useBreakpointValue({base: true, md: false})

    const {shipToCountry} = useShipToCountry()

    // Get the nav menu config page from page designer to display the navigation links
    const staleTime = getEnvStaleTime()
    const {data: navMenuConfigPage} = usePage(
        {parameters: {pageId: NAV_MENU_CONFIG_PDC_PAGE_ID}},
        {staleTime: staleTime}
    )
    const firstLevelTriggers = navMenuConfigPage?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.navMenuConfig.pdcFirstLevelTriggers'
    )?.regions[0]?.components
    const primaryDirectLinks = navMenuConfigPage?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.navMenuConfig.pdcPrimaryDirectLinks'
    )?.regions[0]?.components
    const secondaryDirectLinks = navMenuConfigPage?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.navMenuConfig.pdcSecondaryDirectLinks'
    )?.regions[0]?.components

    useEffect(() => {
        setAriaBusy('false')
    }, [])

    const onCloseNavMenu = () => {
        onClose()
        setItemSelected(null)
    }

    const animation = useAnimation()
    const bind = useGesture(
        {
            onDragEnd: () => {
                isMobile && onCloseNavMenu()
            }
        },
        {
            drag: {
                axis: 'x'
            }
        }
    )
    return (
        <>
            <Drawer isOpen={isOpen} onClose={onCloseNavMenu} placement="left" {...props}>
                <DrawerOverlay>
                    <DrawerContent
                        boxShadow={'none'}
                        maxWidth={['100%', null, itemSelected ? '709px' : '359px']}
                        animate={animation}
                        {...bind()}
                    >
                        {/* Header Content */}
                        <DrawerHeader
                            {...styles.header}
                            aria-label={intl.formatMessage({
                                id: 'drawer_menu.header.assistive_msg.title',
                                defaultMessage: 'Menu Drawer'
                            })}
                        >
                            <DrawerCloseButton top="auto" position={'static'}>
                                <CloseIcon boxSize={[4, null, '14px']} />
                            </DrawerCloseButton>
                        </DrawerHeader>

                        {/* Main Content */}
                        <DrawerBody {...styles.body}>
                            <Box>
                                <div
                                    id="category-nav"
                                    aria-live="polite"
                                    aria-busy={ariaBusy}
                                    aria-atomic="true"
                                >
                                    {/* Category Navigation */}
                                    <Fade in={true}>
                                        {root?.[itemsKey] ? (
                                            <List spacing={[0, null, '6px']}>
                                                {root?.[itemsKey]
                                                    .filter((item) => !!item[NAV_MENU_ITEMS_FILTER])
                                                    .map((item) => {
                                                        const {id, name} = item
                                                        const itemsCount =
                                                            item[itemsCountKey] ||
                                                            item[itemsKey]?.length ||
                                                            0
                                                        return (
                                                            <ListItem key={id}>
                                                                {itemsCount > 0 ? (
                                                                    <DrawerMenuLvl2
                                                                        item={item}
                                                                        itemsKey={itemsKey}
                                                                        itemsFilter={
                                                                            NAV_MENU_ITEMS_FILTER
                                                                        }
                                                                        isOpen={itemSelected === id}
                                                                        onSelectedItem={(isOpen) =>
                                                                            setItemSelected(
                                                                                isOpen ? id : null
                                                                            )
                                                                        }
                                                                        onCloseLvl1={onCloseNavMenu}
                                                                        drawerMenuTriggerRef={
                                                                            drawerMenuTriggerRef
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <Link
                                                                        to={buildUrl(
                                                                            categoryUrlBuilder(item)
                                                                        )}
                                                                    >
                                                                        {name}
                                                                    </Link>
                                                                )}
                                                            </ListItem>
                                                        )
                                                    })}
                                            </List>
                                        ) : (
                                            <PlaceholderComponent />
                                        )}
                                    </Fade>

                                    {/* First level triggers Navigation */}
                                    <Fade in={true}>
                                        {firstLevelTriggers ? (
                                            <List spacing={[0, null, '6px']} mt={[0, null, '34px']}>
                                                {firstLevelTriggers.map((item, i) => {
                                                    const {id} = item
                                                    return (
                                                        <ListItem key={i}>
                                                            <DrawerMenuLvl2
                                                                item={item}
                                                                isOpen={itemSelected === id}
                                                                onSelectedItem={(isOpen) =>
                                                                    setItemSelected(
                                                                        isOpen ? id : null
                                                                    )
                                                                }
                                                                onCloseLvl1={onCloseNavMenu}
                                                                drawerMenuTriggerRef={
                                                                    drawerMenuTriggerRef
                                                                }
                                                            />
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        ) : (
                                            <PlaceholderComponent />
                                        )}
                                    </Fade>
                                </div>

                                {/* Primary direct links */}
                                {primaryDirectLinks ? (
                                    <List mt={['24px', null, '34px']} width="fit-content">
                                        {primaryDirectLinks.map((item, i) => {
                                            return (
                                                <ListItem key={i}>
                                                    <PDCLink
                                                        custom={item?.custom}
                                                        label={item?.data?.label}
                                                        {...styles.primaryDirectLink}
                                                    ></PDCLink>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                ) : (
                                    <PlaceholderComponent />
                                )}
                            </Box>

                            {/* Secondary direct links */}
                            {secondaryDirectLinks ? (
                                <List mt="24px" mb={[0, null, '30px']} width="fit-content">
                                    {secondaryDirectLinks.map((item, i) => {
                                        return (
                                            <ListItem key={i}>
                                                <PDCLink
                                                    custom={item?.custom}
                                                    {...styles.secondaryDirectLink}
                                                >
                                                    <Text
                                                        variant={['bodyBase2', null, 'bodySmall']}
                                                    >
                                                        {item?.data?.label}
                                                    </Text>
                                                </PDCLink>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            ) : (
                                <PlaceholderComponent />
                            )}
                        </DrawerBody>

                        <DrawerFooter {...styles.footer}>
                            <HStack
                                w="full"
                                borderWidth="1px"
                                borderColor={'gray'}
                                gap={0}
                                justifyContent={'center'}
                            >
                                <Link
                                    {...styles.footerLink}
                                    to="/account"
                                    textDecoration="underline"
                                >
                                    {intl.formatMessage({
                                        id: 'drawer_menu.button.account_details',
                                        defaultMessage: 'My account'
                                    })}
                                </Link>
                                <Divider orientation="vertical" {...styles.footerDivider} />
                                {/* TODO : change URL and label of ship-to country link.
                                    It will be implemented during the International Shipping task. */}
                                <Link {...styles.footerLink} to="#">
                                    {intl.formatMessage({
                                        id: 'drawer_menu.button.ship_to_country',
                                        defaultMessage: 'Delivery country: '
                                    })}{' '}
                                    <Text as="span" textDecoration={'underline'}>
                                        {shipToCountry.id}
                                    </Text>
                                </Link>
                                {showLocaleSelector && (
                                    <>
                                        <Divider orientation="vertical" {...styles.footerDivider} />
                                        <DrawerMenuLocaleSelector />
                                    </>
                                )}
                            </HStack>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
            <SEOMenu
                root={root}
                itemsKey={itemsKey}
                itemsFilter="c_showInMenu"
                firstLevelTriggers={firstLevelTriggers}
                primaryDirectLinks={primaryDirectLinks}
                secondaryDirectLinks={secondaryDirectLinks}
            />
        </>
    )
}

DrawerMenu.displayName = 'DrawerMenu'

DrawerMenu.propTypes = {
    root: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onLogoClick: PropTypes.func,
    itemsKey: PropTypes.string,
    itemsCountKey: PropTypes.string,
    itemComponent: PropTypes.elementType,
    drawerMenuTriggerRef: PropTypes.object,
    props: PropTypes.object
}

export {DrawerMenu}
