import React, {useId} from 'react'
import {Link as RouteLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {Box, Flex, AspectRatio, Link, Skeleton, Text, useBreakpointValue} from '@chakra-ui/react'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import PDCTitle from '../../../components/pdc-title'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'
import {Component} from '@salesforce/commerce-sdk-react/components'
import {useUrlFromLink} from '../../helpers/urlHelper.js'

/**
 * PDC About Story Block Excerpt
 * @param {object} page - Page data
 * @param {string} backgroundColor - Background color
 * @param {string} textColor - Text color
 * @param {string} alternativePos - Secondary images and text position
 * @returns {JSX.Element} - PDC About Story Block Excerpt component
 */
export const SimpleMagazineBlogPost = ({page, ...props}) => {
    const intl = useIntl()
    const aboutStoryBlockDefine = page.aboutStoryBlockDefine
    const components = aboutStoryBlockDefine.links
    const isMobile = useBreakpointValue({base: true, md: false})

    const titleId = useId()
    const currentRatio = useBreakpointValue({base: 85 / 110, md: 163 / 200})

    // Get current main image based on breakpoint
    const currentMainImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: aboutStoryBlockDefine?.smallImage,
            mediumEl: aboutStoryBlockDefine?.mediumImage,
            largeEl: aboutStoryBlockDefine?.largeImage,
            xlargeEl: aboutStoryBlockDefine?.xlargeImage,
            xxlargeEl: aboutStoryBlockDefine?.xxlargeImage
        })
    )

    return (
        <Box {...props}>
            <Skeleton
                isLoaded={!!currentMainImage}
                minWidth={'100%'}
                minHeight={['200px', null, '400px']}
                startColor="lightBlue"
                endColor="white"
            >
                <Flex
                    as="article"
                    aria-describedby={titleId}
                    flexDirection={['row', null, 'column']}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    width="100%"
                >
                    <Link
                        as={RouteLink}
                        to={useUrlFromLink({
                            type: 'PAGE',
                            target: page?.id
                        })}
                        flex={['1 0 45%', null, '1 0 100%']}
                        w="full"
                        position="relative"
                    >
                        <AspectRatio ratio={currentRatio}>
                            <DynamicImage
                                src={`${currentMainImage?.url}[?sw={width}]`}
                                widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                                height="100%"
                                imageProps={{
                                    width: '100%',
                                    loading: 'eager',
                                    fetchpriority: 'high',
                                    alt:
                                        aboutStoryBlockDefine?.imageAlt === 'empty'
                                            ? ''
                                            : aboutStoryBlockDefine?.imageAlt ||
                                              aboutStoryBlockDefine?.title,
                                    title:
                                        aboutStoryBlockDefine?.imageTitle === 'empty'
                                            ? ''
                                            : aboutStoryBlockDefine?.imageTitle ||
                                              aboutStoryBlockDefine?.title
                                }}
                            />
                        </AspectRatio>
                    </Link>
                    <Box mt={['20px', null, '34px']} ml={['14px', null, 0]} w="full">
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: `${new Date(page?.publicationDate)?.toLocaleDateString(
                                    intl.locale,
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    }
                                )} - ${page.folderName}`
                            }}
                            variant="eyeBrowSmall"
                            color={aboutStoryBlockDefine?.textColor || 'fullBlack'}
                            textTransform={'uppercase'}
                        />
                        <PDCTitle
                            title={aboutStoryBlockDefine?.title || ''}
                            titleFontColor={'fullblack'}
                            titleHeadingLevel={'h2'}
                            fontSize="2rem"
                            id={titleId}
                            mt={'8px'}
                        />
                        {!isMobile && (
                            <Text
                                dangerouslySetInnerHTML={{
                                    __html: aboutStoryBlockDefine?.desc
                                }}
                                variant="bodyLarge2"
                                color="darkGray"
                                mt={'24px'}
                            />
                        )}

                        {components && (
                            <Box mt={['8px', null, '20px']}>
                                {components.map((component, index) => {
                                    // Override link url because we want to use the url towards the blog article page
                                    // instead of the url defined in the "define" component
                                    component.custom = component.custom ?? {}
                                    component.custom.url = {
                                        type: 'PAGE',
                                        target: page?.id
                                    }

                                    return (
                                        <Component
                                            key={component?.id || index}
                                            component={component}
                                        />
                                    )
                                })}
                            </Box>
                        )}
                    </Box>
                </Flex>
            </Skeleton>
        </Box>
    )
}

SimpleMagazineBlogPost.propTypes = {
    page: PropTypes.object,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    alternativePos: PropTypes.bool
}
