import React from 'react'
import PropTypes from 'prop-types'
import {
    Image,
    Link,
    Text,
    useBreakpointValue
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useUrlFromLink} from '../../helpers/urlHelper'

/**
 * PDCFooterReinsuranceIcon component.
 * @typedef {Object} PDCFooterReinsuranceIconProps
 * @property {object} icon - Icon
 * @property {string} text - Displayed text
 * @property {string} url - Url of the link

 * @returns {React.ReactElement} - PDCFooterReinsuranceIcon component.
 */
export const PDCFooterReinsuranceIcon = ({icon, text, mobileText, url, custom}) => {
    const isDesktop = useBreakpointValue({base: false, lg: true})
    const updatedUrl = useUrlFromLink(custom?.url) || url

    return (
        <Link
            href={updatedUrl}
            textAlign={'center'}
            textDecoration={'none'}
            display={'block'}
            mb={{base: '44px', lg: '34px'}}
        >
            <Image src={icon.url} alt={text} height={'34px'} margin={'auto'} />
            <Text variant={'bodyBase1'} mt={'11px'} textTransform={'uppercase'}>{`. ${
                mobileText && !isDesktop ? mobileText : text
            } .`}</Text>
        </Link>
    )
}

PDCFooterReinsuranceIcon.propTypes = {
    icon: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    mobileText: PropTypes.string,
    url: PropTypes.string.isRequired,
    custom: PropTypes.object
}
