import React from 'react'
import PropTypes from 'prop-types'
import {Box} from '@salesforce/retail-react-app/app/components/shared/ui'

export const PDBottomPLPLayout = ({bottomPLPVariantID, regions}) => {
    const componentConfig = regions?.[0]?.components?.[0] || {}

    return (
        <Box as="section">
            {/* @TODO: PDC-HOME-PATTERN-FINDER-PUSH assets/pdc-home-pattern-finder-push
                       with priority in TSD: to implement AFTER go live
            */}
            {bottomPLPVariantID}
        </Box>
    )
}

PDBottomPLPLayout.propTypes = {
    bottomPLPVariantID: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
