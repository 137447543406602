import {useContext} from 'react'
import {ShipToCountryContext} from '../custom-contexts'

/**
 * Custom hook to access and update the shipping country context.
 * This hook must be used within a ShipToCountryProvider to ensure the context is available.
 *
 * @throws {Error} Throws an error if the hook is used outside of a ShipToCountryProvider.
 * @returns {Object} An object containing:
 *   @property {string} shipToCountry - The current shipping country.
 *   @property {Function} setShipToCountry - Function to update the shipping country.
 */
const useShipToCountry = () => {
    const context = useContext(ShipToCountryContext)

    if (!context) {
        throw new Error('useShipToCountry must be used within a ShipToCountryProvider')
    }

    const {shipToCountry, setShipToCountry} = context

    return {shipToCountry, setShipToCountry}
}

export default useShipToCountry
