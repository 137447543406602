import React, {useState} from 'react'
import {
    Box,
    Text,
    useStyleConfig,
    Heading,
    createStylesContext,
    Image,
    Stack,
    Accordion,
    useBreakpointValue,
    useDisclosure,
    InputRightElement,
    IconButton
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useIntl} from 'react-intl'
import {useForm} from 'react-hook-form'

import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import colors from '../../theme/foundations/colors'
import {usePage} from '@salesforce/commerce-sdk-react'
import {PageContext, Component} from '@salesforce/commerce-sdk-react/components'
import {
    PDCFooterLinksListMobile,
    PDCFooterLinksListDesktop,
    PDCFooterLinksListCheckout,
    PDCFooterReinsurance
} from '../../page-designer/layouts'
import {PDCFooterReinsuranceIcon, PDCLink} from '../../page-designer/assets'
import FooterBottom from './footer-bottom'
import {useLocation} from 'react-router-dom'
import {NewsletterModal} from '../newsletter-modal'
import {useSplioService} from './../../hooks/use-splio-service'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import Field from '../field'
import {RightArrowIcon} from '../custom-icons'
import FieldsetForm from '../shared/ui/FieldsetForm'
import useNewsletterFields from '../forms/useNewsletterFields'
import {useToast} from '@salesforce/retail-react-app/app/hooks/use-toast'
import {useIsLocale} from '../../hooks/use-is-locale'
import {useCurrentCustomer} from '@salesforce/retail-react-app/app/hooks/use-current-customer'
import useSiteType from './../../hooks/use-site-type'
import {useUpdateProfileFields} from './../../hooks/use-update-profile-fields'

// Utils
import {getEnvStaleTime} from '../../utils/utils'

const PAGEDESIGNER_TO_COMPONENT_MOBILE = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListMobile
}

const PAGEDESIGNER_TO_COMPONENT_DESKTOP = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListDesktop
}

const PAGEDESIGNER_TO_COMPONENT_CHECKOUT = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListCheckout
}
const PAGEDESIGNER_TO_COMPONENT_REINSURANCE = {
    'commerce_assets.pdcFooterReinsuranceIcon': PDCFooterReinsuranceIcon,
    'commerce_layouts.pdcFooterReinsurance': PDCFooterReinsurance
}
const [StylesProvider] = createStylesContext('Footer')

/**
 * Footer Component
 *
 * This component renders the footer section of the application, including different layouts
 * for mobile, desktop, and checkout pages. It dynamically loads footer links and other
 * components based on the current page context.
 *
 * @component
 * @example
 * // Usage example:
 * <Footer />
 *
 * @returns {JSX.Element} The rendered footer component.
 */

const Footer = () => {
    const {updateEUConsentFields, updateUSConsentFields} = useUpdateProfileFields()
    const {isEU} = useSiteType()
    const {data: customer} = useCurrentCustomer()
    const {isRegistered, customerId} = customer
    const [newsletterEmail, setNewsletterEmail] = useState(null)
    const styles = useStyleConfig('Footer')
    const showToast = useToast()
    const {formatMessage} = useIntl()
    const isDesktop = useBreakpointValue({base: false, lg: true})
    const location = useLocation()
    const {isAlreadySubscribed, isBlackListedEmail, forwardAccountToSplio} = useSplioService()
    const {site, locale} = useMultiSite()
    const isCheckout = /\/checkout$/.test(location?.pathname)
    let email
    // Get the header page from page designer to display the navigation links
    const staleTime = getEnvStaleTime()
    const {data: footerPage} = usePage({parameters: {pageId: 'footer'}}, {staleTime: staleTime})
    const footerLinksListComponents = footerPage?.regions?.[0].components?.filter(
        (comp) => comp?.typeId === 'commerce_layouts.pdcFooterLinksList'
    )
    const [contextValueMobile] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_MOBILE
    })
    const [contextValueDesktop] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_DESKTOP
    })

    const [contextValueCheckout] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_CHECKOUT
    })

    const checkoutFooterStyle = isDesktop
        ? {
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              pt: '70px'
          }
        : null
    const form = useForm()
    const {
        formState: {isSubmitting}
    } = form
    const fields = useNewsletterFields({form, prefix: ''})

    const {
        isOpen: isNewsletterModalOpen,
        onOpen: onNewsletterModalOpen,
        onClose: onNewsletterModalClose
    } = useDisclosure()

    /**
     * Handles the newsletter subscription process based on the provided data.
     *
     * This function checks if the email is present in the data object. If an email
     * is provided, it sets the newsletter email state and determines the appropriate
     * action based on the user's location (US or non-US).
     *
     * @async
     * @function handleNewsletterSubscription
     * @param {Object} data - The data object containing subscription details.
     * @param {string} data.email - The email address to be subscribed.
     *
     * @returns {void}
     *
     * @example
     * handleNewsletterSubscription({ email: 'user@example.com' });
     *
     * @description
     * - If the email is not present in the data object, the function exits early.
     * - If the user is located in the US, it triggers the Splio call with the email.
     * - If the user is not located in the US, it opens the newsletter modal.
     */
    const isUS = useIsLocale('en-US')

    const handleNewsletterSubscription = async (data) => {
        if (!data.email) {
            return
        }
        setNewsletterEmail(data.email)

        email = data.email
        if (isUS) {
            handleSplioCall(email)
        } else {
            onNewsletterModalOpen()
        }
    }

    /**
     * Handles the subscription process for a newsletter.
     *
     * This asynchronous function manages the subscription process by first checking if the provided
     * email is already subscribed to the newsletter. If the email is already subscribed, it sets an
     * error on the form indicating the subscription status. If not, it further checks if the email
     * is blacklisted. If the email is blacklisted, it sets an appropriate error on the form. If the
     * email passes both checks, it forwards the account details to Splio and displays a success
     * message to the user.
     *
     * @async
     * @function handleSplioCall
     * @returns {Promise<void>} - A promise that resolves when the subscription process is complete.
     */
    const handleSplioCall = async () => {
        if (!newsletterEmail) {
            return
        }

        let requestObject = {
            email: newsletterEmail,
            siteId: site.id,
            locale: locale.id
        }

        const newsletterResult = await isAlreadySubscribed(requestObject)

        if (newsletterResult.success) {
            form.setError('email', {type: 'already', message: newsletterResult.message})
        } else {
            let blackListedResult = await isBlackListedEmail(requestObject)

            /**
             * Handles the result of the blacklist check for an email.
             *
             * If the email is blacklisted, it sets an error on the form. Otherwise, it forwards the account
             * details to Splio and displays a success message.
             */

            if (blackListedResult.success) {
                form.setError('email', {type: 'blacklisted', message: blackListedResult.message})
            } else {
                await forwardAccountToSplio({
                    email: newsletterEmail,
                    firstName: '',
                    lastName: '',
                    phoneHome: '',
                    marketingConsent: true
                })

                if (customerId && isRegistered) {
                    if (isEU) {
                        updateEUConsentFields()
                    } else {
                        updateUSConsentFields()
                    }
                }

                showToast({
                    title: formatMessage({
                        defaultMessage: 'Subscription successful',
                        id: 'newsletter.subscription_successful'
                    }),
                    status: 'success'
                })
            }
        }
    }

    return (
        <Box as="footer" {...styles.container}>
            {isCheckout ? null : <PreFooter></PreFooter>}
            <Box {...styles.content} bg={colors.lightBeige}>
                <StylesProvider value={styles}>
                    <Box sx={isCheckout ? checkoutFooterStyle : null}>
                        <Stack
                            direction={{base: 'column', lg: 'row'}}
                            spacing={'44px'}
                            pt={'44px'}
                            pb={{base: '44px', lg: '140px'}}
                            px={{base: '10px', lg: '20px'}}
                        >
                            {isCheckout ? null : (
                                <Box flexBasis={'33%'}>
                                    <Heading
                                        as="h4"
                                        fontSize="3xl"
                                        textTransform={'uppercase'}
                                        mb={13}
                                    >
                                        {formatMessage({
                                            id: 'footer.newsletter.title',
                                            defaultMessage: 'Newsletter'
                                        })}
                                    </Heading>
                                    <Text variant="bodyBase2" color={colors.darkGray} maxW={'280'}>
                                        {formatMessage({
                                            id: 'footer.newsletter.info',
                                            defaultMessage:
                                                'Sign in to receive our latest news about collections, products, shows and collaborations'
                                        })}
                                    </Text>
                                    <FieldsetForm
                                        id="newsletter-form"
                                        data-testid="sf-auth-modal-form"
                                        legendText={formatMessage({
                                            defaultMessage: 'Newsletter form',
                                            id: 'newsletter_form.form.legend'
                                        })}
                                        onSubmit={form.handleSubmit(handleNewsletterSubscription)}
                                        error={form.formState.errors?.global?.message}
                                        isMandatoryNote={false}
                                    >
                                        <Box maxW={'343px'} mt={'34px'}>
                                            <Field {...fields.email}>
                                                <InputRightElement>
                                                    <IconButton
                                                        type="submit"
                                                        size="inputElement"
                                                        icon={
                                                            <RightArrowIcon
                                                                color="fullBlack"
                                                                boxSize={5}
                                                            />
                                                        }
                                                        onClick={() => form.clearErrors('global')}
                                                        isLoading={isSubmitting}
                                                    />
                                                </InputRightElement>
                                            </Field>
                                        </Box>
                                    </FieldsetForm>
                                    <NewsletterModal
                                        isOpen={isNewsletterModalOpen}
                                        onClose={onNewsletterModalClose}
                                        submitNewsletter={() => {
                                            handleSplioCall()
                                            onNewsletterModalClose()
                                        }}
                                    ></NewsletterModal>
                                </Box>
                            )}
                            <Box flexBasis={isCheckout ? null : '33%'} alignSelf={'center'}>
                                <Image
                                    align={'center'}
                                    width={{base: '173', lg: '214'}}
                                    height={{base: '170', lg: '210'}}
                                    margin={'auto'}
                                    src={getAssetUrl('static/img/turtle.svg')}
                                    alt={formatMessage({
                                        id: 'footer.turtle',
                                        defaultMessage: 'Turtle'
                                    })}
                                />
                            </Box>
                            {isCheckout ? null : (
                                <Box flexBasis={'33%'} textAlign={{base: 'left', lg: 'right'}}>
                                    {/* TODO: TO REMOVE ONCE REAL STARS RATING IS IMPLEMENTED */}
                                    <Text variant="bodyBase1">Stars rating</Text>
                                </Box>
                            )}
                        </Stack>
                        <Box px={{base: isCheckout ? '10px' : '0', lg: '20px'}} flexGrow={1}>
                            {isCheckout ? (
                                <PageContext.Provider value={contextValueCheckout}>
                                    <Stack direction={'row'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === true
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Box
                                                    key={index}
                                                    flex={'0 1 20%'}
                                                    mb={isDesktop ? '' : '44px'}
                                                >
                                                    <Component component={footerLinksList} />
                                                </Box>
                                            ))}
                                    </Stack>
                                </PageContext.Provider>
                            ) : isDesktop ? (
                                <PageContext.Provider value={contextValueDesktop}>
                                    <Stack direction={'row'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === false
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Box key={index} flex={'0 1 20%'}>
                                                    <Component component={footerLinksList} />
                                                </Box>
                                            ))}
                                    </Stack>
                                </PageContext.Provider>
                            ) : (
                                <PageContext.Provider value={contextValueMobile}>
                                    <Accordion allowToggle mb={'44px'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === false
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Component
                                                    key={index}
                                                    component={footerLinksList}
                                                />
                                            ))}
                                    </Accordion>
                                </PageContext.Provider>
                            )}
                        </Box>
                    </Box>
                    <FooterBottom />
                </StylesProvider>
            </Box>
        </Box>
    )
}

export default Footer

/**
 * PreFooter Component
 *
 * This component renders the pre-footer section, which includes reinsurance information.
 * It dynamically loads the reinsurance component based on the current page context.
 *
 * @component
 * @example
 * // Usage example:
 * <PreFooter />
 *
 * @returns {JSX.Element} The rendered pre-footer component.
 */

const PreFooter = () => {
    const staleTime = getEnvStaleTime()
    const {data: footerPage} = usePage({parameters: {pageId: 'footer'}}, {staleTime: staleTime})
    const footerReinsuranceComponent = footerPage?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.pdcFooterReinsurance'
    )
    const [contextValueReinsurance, setContextValueReinsurance] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_REINSURANCE
    })
    return (
        <>
            {footerReinsuranceComponent && (
                <PageContext.Provider value={contextValueReinsurance}>
                    <Component component={footerReinsuranceComponent} />
                </PageContext.Provider>
            )}
        </>
    )
}
PreFooter.propTypes = {}
