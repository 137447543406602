import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Grid,
    GridItem,
    useBreakpointValue
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {usePage} from '@salesforce/commerce-sdk-react'
import {PDCAboutStoryBlockExcerpt} from '../../layouts'
import {SimpleMagazineBlogPost} from '../../layouts'
import {MAGAZINE_NB_ARTICLES_PER_PAGE, MAGAZINE_NB_ARTICLES_INITIAL} from '../../../constants.js'

// Utils
import {getEnvStaleTime} from '../../../utils/utils.js'

/**
 * PDCBlogArticlesList
 * Displays a list of articles from a specific folder
 *
 * @param {object} props
 * @returns {React.ReactElement}
 */
export const PDCBlogArticlesList = ({custom}) => {
    const intl = useIntl()
    const {pageId} = useParams()
    const staleTime = getEnvStaleTime()
    const {data: page, error} = usePage({parameters: {pageId}}, {staleTime: staleTime})

    const articlePages = page?.custom?.articlePages || []
    const [pagesToDisplay, setPagesToDisplay] = useState(
        articlePages.slice(0, MAGAZINE_NB_ARTICLES_INITIAL)
    )
    const [visibleItemCount, setVisibleItemCount] = useState(MAGAZINE_NB_ARTICLES_INITIAL)

    // Handler for the "Load More" button
    const handleLoadMore = () => {
        setVisibleItemCount((prevCount) => prevCount + MAGAZINE_NB_ARTICLES_PER_PAGE)
        setPagesToDisplay(articlePages.slice(0, visibleItemCount + MAGAZINE_NB_ARTICLES_PER_PAGE))
    }

    const colSpan = useBreakpointValue({
        base: 1,
        md: 2,
        lg: 3
    })

    return (
        !error && (
            <Box textAlign={'center'}>
                <Grid
                    as="ul"
                    templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                    listStyleType={'none'}
                >
                    {pagesToDisplay?.map((page, i) => (
                        <GridItem as="li" colSpan={i % 7 === 0 ? colSpan : 1} key={i}>
                            {i % 7 === 0 ? (
                                <PDCAboutStoryBlockExcerpt page={page} />
                            ) : (
                                <SimpleMagazineBlogPost
                                    p={['20px 15px', null, '34px 40px', '64px 77px']}
                                    page={page}
                                />
                            )}
                        </GridItem>
                    ))}
                </Grid>
                {visibleItemCount < articlePages.length && (
                    <Button
                        variant="secondary"
                        onClick={handleLoadMore}
                        m={['30px 0 54px', null, '44px 0 94px']}
                    >
                        {intl.formatMessage(
                            {
                                id: 'magazine.pagination.load_more',
                                defaultMessage: 'Load more + {nbOfArticlesPerPage}'
                            },
                            {
                                nbOfArticlesPerPage: MAGAZINE_NB_ARTICLES_PER_PAGE
                            }
                        )}
                    </Button>
                )}
            </Box>
        )
    )
}

PDCBlogArticlesList.propTypes = {
    custom: PropTypes.object
}
