import React, {useId} from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import {Box, Flex, AspectRatio, Text, HStack, useBreakpointValue} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import PDCTitle from '../../../components/pdc-title'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'
import {PDCLink} from '../../assets/pdc-link'

/**
 * PDC About Story Block Define
 * @param {string} title - Title of the component
 * @param {string} titleHeadingLevel - Title font size
 * @param {string} titleFontColor - Title font color
 * @param {string} subTitle - Subtitle
 * @param {string} desc - Description
 * @param {string} backgroundColor - Background color
 * @param {string} textColor - Text color
 * @param {object} smallImage - Main small image
 * @param {object} mediumImage - Main medium image
 * @param {object} largeImage - Main large image
 * @param {object} xlargeImage - Main xlarge image
 * @param {object} xxlargeImage - Main xxlarge image
 * @param {string} imageTitle - Main image title
 * @param {string} imageAlt - Main image alt
 * @param {object} seclargeImage - Secondary large image
 * @param {object} secXlargeImage - Secondary xlarge image
 * @param {object} secXxlargeImage - Secondary xxlarge image
 * @param {string} secImageTitle - Secondary image title
 * @param {string} secImageAlt - Secondary image alt
 * @param {object} sec2largeImage - Secondary 2 large image
 * @param {object} sec2XlargeImage - Secondary 2 xlarge image
 * @param {object} sec2XxlargeImage - Secondary 2 xxlarge image
 * @param {string} sec2ImageTitle - Secondary 2 image title
 * @param {string} sec2ImageAlt - Secondary 2 image alt
 * @param {string} alternativePos - Secondary images and text position
 * @param {string} linkUrl - Link URL
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC About Story Block Define component
 */
export const PDCAboutStoryBlockDefine = ({
    title,
    titleHeadingLevel,
    titleFontColor,
    subTitle,
    desc,
    backgroundColor,
    textColor,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt,
    secLargeImage,
    secXlargeImage,
    secXxlargeImage,
    secImageTitle,
    secImageAlt,
    sec2LargeImage,
    sec2XlargeImage,
    sec2XxlargeImage,
    sec2ImageTitle,
    sec2ImageAlt,
    alternativePos,
    linkUrl,
    regions
}) => {
    let components = []
    if (regions) {
        components = regions[0]?.components || []
    }

    const intl = useIntl()
    const titleId = useId()
    const currentRatio = useBreakpointValue({base: 207 / 275, md: 143 / 180})
    const isDesktop = useBreakpointValue({base: false, lg: true})

    // Get current main image based on breakpoint
    const currentMainImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    // Get current first secondary image based on breakpoint
    const currentSec1Image = useBreakpointValue(
        usePDCResponsiveElement({
            largeEl: secLargeImage,
            xlargeEl: secXlargeImage,
            xxlargeEl: secXxlargeImage
        })
    )

    // Get current second secondary image based on breakpoint
    const currentSec2Image = useBreakpointValue(
        usePDCResponsiveElement({
            largeEl: sec2LargeImage,
            xlargeEl: sec2XlargeImage,
            xxlargeEl: sec2XxlargeImage
        })
    )

    const secondaryImagesBlock = isDesktop && (
        <HStack gap="9px" w="full">
            <DynamicImage
                src={`${currentSec1Image?.url}[?sw={width}]`}
                widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                imageProps={{
                    width: '100%',
                    height: '100%',
                    loading: 'auto',
                    fetchpriority: 'high',
                    objectFit: 'cover',
                    alt: secImageAlt === 'empty' ? '' : secImageAlt || title,
                    title: secImageTitle === 'empty' ? '' : secImageTitle || title
                }}
                flex="1"
                height="100%"
            />
            <DynamicImage
                src={`${currentSec2Image?.url}[?sw={width}]`}
                widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                imageProps={{
                    width: '100%',
                    height: '100%',
                    loading: 'auto',
                    fetchpriority: 'high',
                    objectFit: 'cover',
                    alt: sec2ImageAlt === 'empty' ? '' : sec2ImageAlt || title,
                    title: sec2ImageTitle === 'empty' ? '' : sec2ImageTitle || title
                }}
                flex="1"
                height="100%"
            />
        </HStack>
    )

    const textBlock = (
        <Box mt={['32px', null, 0]} w="full" textAlign={'left'}>
            {subTitle && (
                <Text
                    dangerouslySetInnerHTML={{
                        __html: subTitle
                    }}
                    variant="eyeBrowSmall"
                    color={textColor ? textColor : 'fullBlack'}
                    mb={['8px', null, '24px']}
                />
            )}
            <PDCTitle
                title={title || ''}
                titleFontColor={titleFontColor}
                titleHeadingLevel={titleHeadingLevel}
                textColor={textColor}
                size="4xl"
                id={titleId}
            />
            {desc && (
                <Text
                    dangerouslySetInnerHTML={{
                        __html: desc
                    }}
                    variant="bodyLarge2"
                    color="darkGray"
                    mt={'24px'}
                />
            )}
            {components && components.length > 0 && (
                <Box mt="24px">
                    {components.map((component, index) => {
                        component.backgroundColor = backgroundColor
                        component.textColor = textColor
                        component.width = 'auto'
                        // Override linkUrl if we are in the case of an inclusion in PDCAboutStoryBlockExcerpt
                        // where we want to use the linkUrl of the "excerpt" instead of the url of the "define" component
                        if (linkUrl) {
                            component.custom = component.custom ?? {}
                            component.custom.url = {
                                type: 'PAGE',
                                target: linkUrl
                            }
                        }
                        return <Component key={component?.id || index} component={component} />
                    })}
                </Box>
            )}
            {linkUrl && (
                <Box mt="24px">
                    <PDCLink
                        custom={{
                            url: {
                                type: 'PAGE',
                                target: linkUrl
                            }
                        }}
                        label={intl.formatMessage({
                            id: 'magazine.link.read_more',
                            defaultMessage: 'Read more'
                        })}
                        display={'inline-block'}
                    />
                </Box>
            )}
        </Box>
    )

    return (
        <Box
            as="article"
            aria-describedby={titleId}
            display="flex"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="stretch"
            width="100%"
            height={'auto'}
            backgroundColor={backgroundColor}
            color={textColor}
            p={['30px 44px', null, '34px', '70px']}
        >
            <Box width={['100%', '100%', '50%']} position="relative">
                <AspectRatio ratio={currentRatio} height={'100%'}>
                    <DynamicImage
                        src={`${currentMainImage?.url}[?sw={width}]`}
                        widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                        height="100%"
                        imageProps={{
                            width: '100%',
                            height: '100%',
                            loading: 'eager',
                            fetchpriority: 'high',
                            objectFit: 'cover',
                            alt: imageAlt === 'empty' ? '' : imageAlt || title,
                            title: imageTitle === 'empty' ? '' : imageTitle || title
                        }}
                    />
                </AspectRatio>
            </Box>
            <Flex
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
                width={['100%', null, '50%']}
                p={[0, null, '0 10px 0 70px', '0 101px 0 161px']}
            >
                {alternativePos ? (
                    <>
                        {secondaryImagesBlock}
                        {textBlock}
                    </>
                ) : (
                    <>
                        {textBlock}
                        {secondaryImagesBlock}
                    </>
                )}
            </Flex>
        </Box>
    )
}

PDCAboutStoryBlockDefine.propTypes = {
    title: PropTypes.string,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    subTitle: PropTypes.string,
    desc: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    smallImage: PropTypes.object,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    secLargeImage: PropTypes.object,
    secXlargeImage: PropTypes.object,
    secXxlargeImage: PropTypes.object,
    secImageTitle: PropTypes.string,
    secImageAlt: PropTypes.string,
    sec2LargeImage: PropTypes.object,
    sec2XlargeImage: PropTypes.object,
    sec2XxlargeImage: PropTypes.object,
    sec2ImageTitle: PropTypes.string,
    sec2ImageAlt: PropTypes.string,
    alternativePos: PropTypes.bool,
    linkUrl: PropTypes.string,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
