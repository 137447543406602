import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'

// Components
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    Fade,
    Flex,
    Text,

    // Hooks
    useBreakpointValue,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'
import {ChevronRightIcon, CloseIcon} from '../custom-icons'

import {
    DrawerMenuLvl2CategoriesDesktop,
    DrawerMenuLvl2CategoriesMobile
} from './drawer-menu-lvl2-categories'
import {DrawerMenuLvl2PDPages} from './drawer-menu-lvl2-pd-pages'
import DrawerMenuLvl2MobHeader from './drawer-menu-lvl2-mob-header'
import DrawerMenuLvl2Footer from './drawer-menu-lvl2-footer'
import {CATEGORY_DISPLAY_NAME_FIELD} from './constants'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

/**
 * Drawer Menu Level 2
 *
 * This is the level 2 panel of the drawer menu navigation component.
 *
 * @param {object} props - the props of the component
 * @param {object} props.item - the item to be rendered
 * @param {string} props.itemsKey - the key of the items to be rendered
 * @param {string|function} props.itemsFilter - the filter to be applied to the items
 * @param {boolean} props.isOpen - the state of the drawer
 * @param {function} props.onSelectedItem - the function to be called when an item is selected
 * @param {function} props.onCloseLvl1 - the function to be called when the drawer is closed
 * @param {object} props.drawerMenuTriggerRef - the ref of the trigger button
 *
 * @returns {React.ReactElement}
 */
const DrawerMenuLvl2 = ({
    item,
    itemsKey,
    itemsFilter,
    isOpen,
    onSelectedItem,
    onCloseLvl1,
    drawerMenuTriggerRef
}) => {
    const btnRef = useRef(null)
    const backLinkRef = useRef()

    // the final focus ref is used to set the focus back to the trigger button when the drawer is closed
    const [finalFocusRef, setFinalFocusRef] = useState(btnRef)

    const {buildUrl} = useMultiSite()

    const styles = useMultiStyleConfig('DrawerMenu')

    // Get the name of the item to be displayed
    const itemName =
        itemsKey === 'categories'
            ? item?.[CATEGORY_DISPLAY_NAME_FIELD] || item?.name
            : item?.data?.title

    const isMobile = useBreakpointValue({
        base: true,
        md: false
    })

    const close = () => {
        onSelectedItem(false)
    }

    const open = () => {
        onSelectedItem(true)
    }

    return (
        <>
            <Button
                variant={'link'}
                ref={btnRef}
                color={!isMobile && isOpen ? 'blue' : 'fullBlack'}
                {...styles.lvl2Trigger}
                onClick={open}
            >
                <Text as="span" role="heading" aria-level="2">
                    {itemName}
                </Text>
                {(isMobile || isOpen) && <ChevronRightIcon boxSize="10px" ml={6} />}
            </Button>
            {isMobile ? (
                <Drawer
                    isOpen={isOpen}
                    onClose={() => {
                        onCloseLvl1()
                    }}
                    placement="right"
                    initialFocusRef={backLinkRef}
                    finalFocusRef={finalFocusRef}
                    size="full"
                >
                    <DrawerContent>
                        <DrawerHeader {...styles.header}>
                            <DrawerCloseButton
                                top="auto"
                                position={'static'}
                                onClick={() => {
                                    setFinalFocusRef(drawerMenuTriggerRef)
                                }}
                            >
                                <CloseIcon boxSize={[4, null, '14px']} />
                            </DrawerCloseButton>
                        </DrawerHeader>
                        <DrawerBody p={[0, 0, 0, 0]}>
                            <Flex bg="white" width="100%" flexDirection="column">
                                <DrawerMenuLvl2MobHeader
                                    name={itemName}
                                    viewAllLink={
                                        itemsKey === 'categories'
                                            ? buildUrl(categoryUrlBuilder(item))
                                            : null
                                    }
                                    close={close}
                                    backLinkRef={backLinkRef}
                                />
                                {itemsKey == 'categories' ? (
                                    <DrawerMenuLvl2CategoriesMobile
                                        item={item}
                                        itemsKey={itemsKey}
                                        itemsFilter={itemsFilter}
                                        height={'100%'}
                                    />
                                ) : (
                                    <DrawerMenuLvl2PDPages item={item} p="10px" />
                                )}
                                <DrawerMenuLvl2Footer item={item} pb="34px" />
                            </Flex>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            ) : (
                <Fade in={isOpen} aria-hidden={!isOpen} unmountOnExit={false}>
                    <Box {...styles?.lvl2DesktopContent} pointerEvents={isOpen ? 'auto' : 'none'}>
                        {itemsKey == 'categories' ? (
                            <DrawerMenuLvl2CategoriesDesktop
                                item={item}
                                itemsKey={itemsKey}
                                itemsFilter={itemsFilter}
                                isOpen={isOpen}
                                p="60px 34px 34px"
                            />
                        ) : (
                            <DrawerMenuLvl2PDPages
                                item={item}
                                isOpen={isOpen}
                                p="24px 20px"
                                width="360px"
                            />
                        )}
                        <DrawerMenuLvl2Footer item={item} isOpen={isOpen} mx="34px" mb="29px" />
                    </Box>
                </Fade>
            )}
        </>
    )
}

DrawerMenuLvl2.displayName = 'DrawerMenuLvl2'

DrawerMenuLvl2.propTypes = {
    item: PropTypes.object.isRequired,
    itemsKey: PropTypes.string,
    itemsCountKey: PropTypes.string,
    itemsFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    isOpen: PropTypes.bool,
    onCloseLvl1: PropTypes.func,
    onSelectedItem: PropTypes.func,
    drawerMenuTriggerRef: PropTypes.object
}

export default DrawerMenuLvl2
